<template>
  <q-card class="myip-info-card" >
    <q-item class="myip-item-cardtitle">
      <q-item-section avatar>
          <q-avatar square ><img src="../assets/browser.svg" width="40" height="40" alt="Your browser information"></q-avatar>
      </q-item-section>
      <q-item-section>
        <q-item-label class="myip-info-title text-h5">Your Browser</q-item-label>
        <q-item-label caption>
          Breaking down your browser's technical profile.
        </q-item-label>
      </q-item-section>
      <q-btn
        class="myip-btn-vis"
        aria-label="show/hide Browser info panel"
        round
        flat
        size="md"
        :icon="localvis ? matKeyboardDoubleArrowUp : matKeyboardDoubleArrowDown"
        @click="updateVis(localkey)"
      ></q-btn>
    </q-item>

    <q-slide-transition >
      <div v-show="localvis">
        <q-list>
          <q-separator></q-separator>
          <q-item class="myip-item-hasbadge">
            <q-badge color="brown-7" text-color="white" label="BROWSER" class="myip-badge"></q-badge>
            <q-item-section>
              <div v-if="extendedLoading">
                <q-item-label class="text-body2">Detecting Browser...</q-item-label>
              </div>
              <div v-else-if="userAgent">
                <q-item-label class="text-body1 text-weight-medium">{{ browserName }} {{ browserVersion }} engine: {{ engineName }} {{ engineVersion }}<q-btn flat round color="primary" :icon="matContentCopy" @click="copy(browserName + ' ' + browserVersion + ' engine: ' + engineName + ' ' + engineVersion)" aria-label="copy browser info"></q-btn></q-item-label>              
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected</q-item-label>
              </div>              
            </q-item-section>
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on what is your browser name"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                <div><strong>Browser Name:</strong> Identifies the specific web browser you're using and its current version. Browsers are software applications used to access the web, and knowing the version helps in understanding feature support and potential vulnerabilities.</div><div><strong>Engine Name:</strong> Every browser uses an underlying 'engine' to render web content. This engine determines how web pages look and behave. The engine name and version provide insights into the core technology driving your browser.</div>
              </q-menu>  
            </q-btn>
          </q-item>

          <q-separator class="myip-separator"></q-separator>
          <q-item class="myip-item-hasbadge">
            <q-badge color="brown-7" text-color="white" label="USER AGENT" class="myip-badge"></q-badge>
            <q-item-section>          
              <div v-if="userAgent">
                <q-item-label class="text-body1 text-weight-medium">{{ userAgent }}<q-btn flat round color="primary" :icon="matContentCopy" @click="copy(userAgent)" aria-label="copy useragent"></q-btn></q-item-label>                          
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected</q-item-label>
              </div>        
            </q-item-section>      
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on what is your browser useragent"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                <strong>User Agent:</strong> A unique identifier string sent by web browsers or other client devices to servers. It provides details about the browser, operating system, and sometimes the device, allowing websites to tailor content and functionality for optimal user experience. It's a key element in web analytics, compatibility checks, and adaptive web design.
              </q-menu>  
            </q-btn>
          </q-item>

          <q-separator class="myip-separator"></q-separator>
          <q-item class="myip-item-hasbadge">
            <q-badge color="brown-7" text-color="white" label="ACCEPT ENCODING" class="myip-badge"></q-badge>
            <q-item-section>
              <div v-if="acceptEncoding">
                <q-item-label class="text-body1 text-weight-medium">{{ acceptEncoding }}</q-item-label>
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected</q-item-label>
              </div>        
            </q-item-section>      
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on the accept encoding setting for your browser"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                <strong>Accept Encoding:</strong> A request header web clients use to signify supported compression algorithms, such as 'gzip', 'deflate', or 'br' (Brotli). By understanding these preferences, servers can send compressed content, ensuring faster web page loads and efficient bandwidth usage. This header plays a pivotal role in web performance optimization and seamless user experience.
              </q-menu>  
            </q-btn>
          </q-item>
          
          <q-separator class="myip-separator"></q-separator>
          <q-item class="myip-item-hasbadge">
            <q-badge color="brown-7" text-color="white" label="ACCEPT LANGUAGE" class="myip-badge"></q-badge>
            <q-item-section>
              <div v-if="acceptLanguage">
                <q-item-label class="text-body1 text-weight-medium">{{ acceptLanguage }}</q-item-label>
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected</q-item-label>
              </div>        
            </q-item-section>      
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on the accept langauge setting for your browser"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                <strong>Accept Language:</strong> A request header that web clients use to indicate their preferred languages, often formatted like 'en-US' or 'fr-FR'. Based on this, servers can deliver content in the user's preferred language, enhancing accessibility and user experience. It's instrumental in multilingual websites for tailoring content to a global audience.
              </q-menu>  
            </q-btn>
          </q-item>
        
          <q-separator class="myip-separator"></q-separator>
          <q-item class="myip-item-hasbadge">
            <q-badge color="brown-7" text-color="white" label="PLATFORM" class="myip-badge"></q-badge>
            <q-item-section>          
              <div v-if="userAgent">
                <div v-if="platformType">
                  <q-item-label class="text-body1 text-weight-medium">{{ platform }}</q-item-label>
                </div>
                <div v-if="platformVendor">
                  <q-item-label class="text-body1 text-weight-medium">{{ platformVendor }} {{ platformModel }}</q-item-label>
                </div>
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected</q-item-label>
              </div>        
            </q-item-section>      
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on what platform your browser is running on"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                <div><strong>Platform:</strong> Device type derived from browser identifiers.</div>
                <div><strong>Bot:</strong> Web crawlers like Googlebot.</div>
                <div><strong>Mobile:</strong> Handhelds like iPhone, Huawei, and Android phones.</div>
                <div><strong>Tablet:</strong> Touch devices such as iPad and Android tablets.</div>
                <div><strong>Desktop:</strong> PCs with macOS, Windows, or Linux.</div>
                <div><strong>TV:</strong> Entertainment systems like PlayStation 4 and Roku.</div>
                <div>Determining your platform lets websites optimize content and layout for your device, enhancing your browsing experience.</div>
              </q-menu>  
            </q-btn>
          </q-item>

          <q-separator class="myip-separator"></q-separator>
          <q-item class="myip-item-hasbadge">
            <q-badge color="brown-7" text-color="white" label="OPERATING SYSTEM" class="myip-badge"></q-badge>
            <q-item-section>          
              <div v-if="userAgent">
                <div v-if="osString">
                  <q-item-label class="text-body1 text-weight-medium">{{ osString }}</q-item-label>
                </div>
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected</q-item-label>
              </div>        
            </q-item-section>      
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on what operating system your browser is running on"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
              <div><strong>OS Name & Version:</strong> This indicates your device's operating system and its specific version. Common OS types include:</div>
              <div><strong>Windows:</strong> Used on most desktops/laptops (e.g., Windows 10, 11).</div>
              <div><strong>macOS:</strong> Apple's OS for Macs (e.g., Monterey, Big Sur).</div>
              <div><strong>iOS:</strong> For Apple's iPhones and iPads.</div>
              <div><strong>Android:</strong> Google's system for many smartphones/tablets.</div>
              <div><strong>Linux:</strong> Open-source OS with variants like Ubuntu or Fedora.</div>
              <div>This info aids in software compatibility and tailored user experiences.</div>
              </q-menu>  
            </q-btn>
          </q-item>

          <q-separator class="myip-separator"></q-separator>
          <q-item class="myip-item-hasbadge">
            <q-badge color="brown-7" text-color="white" label="SCREEN" class="myip-badge"></q-badge>
            <q-item-section>          
              <div v-if="screenWidth && screenHeight">
                <q-item-label class="text-body2">Dimensions (w x h): <span class="text-weight-medium">{{ screenWidth }} x {{ screenHeight }}px</span></q-item-label>            
              </div>
              <div v-if="viewportWidth && viewportHeight">
                <q-item-label class="text-body2">Viewport (w x h): <span class="text-weight-medium">{{ viewportWidth }} x {{ viewportHeight }}px</span></q-item-label>                      
              </div>
              <div v-if="colorDepth">
                <q-item-label class="text-body2">Color Depth: <span class="text-weight-medium">{{ colorDepth }}bit</span></q-item-label>                      
              </div>
            </q-item-section>      
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info what are the screen dimensions and viewport for your browser"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
              <div><strong>Screen:</strong> Overview of your device's display attributes.</div>
              <div><strong>Dimensions (w x h):</strong> Physical screen resolution in pixels; total pixels your device can display.</div>
              <div><strong>Viewport (w x h):</strong> Visible area in pixels inside the browser, potentially differing due to browser overlays, toolbars etc.</div>
              <div><strong>Color Depth:</strong> Bits used for each pixel's color. E.g. 24-bit indicates 16.7 million possible colors.</div>
              </q-menu>  
            </q-btn>
          </q-item>

        </q-list>
      </div>
    </q-slide-transition>
  </q-card>
</template>

<script setup>
  import { copy, getVisState, toggleVis } from '../utils.js';
  import { matContentCopy, matKeyboardDoubleArrowUp, matKeyboardDoubleArrowDown } from '@quasar/extras/material-icons';
  import { ref, computed } from 'vue';

  const screenWidth = screen.width;
  const screenHeight = screen.height;
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  const colorDepth = screen.colorDepth;

  const defaultvis = true;              //default for this card
  const localkey = 'browserVis';
  const localvis = ref(defaultvis); 

  getVisState(localvis, localkey, defaultvis);

  //refs (eg localvis) when used in a template get automatically unwrapped, need to call toggleVis from within script setup to maintain reactivity
  const updateVis = (localKey) => {
    toggleVis(localvis, localKey);
  }

  const props = defineProps({
    userAgent: String,
    browserName: String,
    browserVersion: String,
    osName: String,
    osVersion: String,
    osVersionName: String,
    platformType: String,
    platformVendor: String,
    platformModel: String,
    engineName: String,
    engineVersion: String,
    acceptEncoding: String,
    acceptLanguage: String,
    extendedLoading: Boolean,
  });

  const osString = computed(() => {
      let result = props.osName;  
      if (props.osVersionName && props.osVersion) {
        result += ' ' + props.osVersionName + ' (' + props.osVersion + ')';
      } else {
        if (props.osVersion) {
          result += ' ' + props.osVersion;
        } else {
          result += ' ' + props.osVersionName;
        }
      }
      return result;
  });

  const platform = computed(() => {
      let result = '';
      if (props.platformType){
          result = props.platformType;
          result = result.charAt(0).toUpperCase() + result.slice(1);
      }  
      return result;
  });

</script>
