<template>

  <q-card class="myip-info-card" >
    <q-item class="myip-item-cardtitle">
      <q-item-section avatar>
          <q-avatar square ><img src="../assets/location.svg" width="40" height="40" alt="Approximate location information"></q-avatar>
      </q-item-section>
      <q-item-section>
        <q-item-label class="myip-info-title text-h5" v-html="locationtitle"></q-item-label>
        <q-item-label caption v-html="locationcaption1"></q-item-label>
        <q-btn
          v-show="tabvis"
          aria-label="show/hide AI travel advice"
          class="locationcap2"
          rounded
          outline
          no-wrap
          dense
          size="md"
          :label="locationcaption2"
          :icon="matMyLocation"
          @click="getLoc()"
        ></q-btn>
      </q-item-section>
      <div v-if="locationrefresh">
        <q-item-section>
          <q-spinner-orbit color="primary" size="md" class="myip-locspin-working"></q-spinner-orbit>
        </q-item-section>
      </div>

      <q-btn
        class="myip-btn-vis"
        aria-label="show/hide IP location panel"
        round
        flat
        size="md"
        :icon="tabvis ? matKeyboardDoubleArrowUp : matKeyboardDoubleArrowDown"
        @click="updateVis(tabkey, 0)"
      ></q-btn>

    </q-item>

      <div v-show="tabvis">
        <q-list>
          <q-separator></q-separator>          
          <!-- <div :class="{ locationblur: extendedLoading || locationLoading }"> -->
          <div id="map" ref="mapContainer"></div>
          <!-- </div> -->
          <q-separator></q-separator>
          <q-item class="myip-item-hasbadge-xtra">
            <q-badge color="indigo-6" text-color="white" label="LOCATION LOADING..." class="myip-badge" v-html="locationbadge"></q-badge>
            <q-item-section>
            <div :class="{ locationblur: extendedLoading || locationLoading }">
              <div v-if="havelocation=='1'">
                <q-item-label class="text-body1" v-if="extrapresent && extra.enLabel && extra.enDescription">{{extra.enLabel}}, {{extra.enDescription}}</q-item-label>
              </div>
              <div v-else>
                <q-item-label class="text-body2">not detected</q-item-label>
              </div>
            </div>
            </q-item-section>
            <div :class="{ locationblur: extendedLoading || locationLoading }">
            <q-btn flat round no-caps color="primary" class="myip-btn-info" aria-label="show info on location accuracy"> 
              <q-avatar size="32px"><img src="../assets/information.svg" width="32" height="32" alt=""></q-avatar>            
              <q-menu class="text-body2 q-pa-sm myip-info-popover" max-width="350px" :offset="[-20, -20]" clickable auto-close>
                <strong>Location:</strong> This is your approximate position, derived from network signals like your IP address. It's important to remember that geolocation isn't entirely accurate and can't pinpoint your exact location. It provides a general idea of your area, useful for region-specific services or content. However, variations in network routing and IP allocation mean that this information should be considered as approximate rather than precise positioning.
              </q-menu>  
            </q-btn>
            </div>            
          </q-item>
          <q-item>
            <q-item-section>
              <div class="location-wrapper" :class="{ locationblur: extendedLoading || locationLoading }">
                <div class="location">
                    <div v-if="havelocation=='1'">
                      <div class="location-address">
                        <div class="location-address-details">
                          <span v-html="getAddress(true)"></span>
                        </div>
                        <div class="location-address-copy">
                          <q-btn flat round color="primary" :icon="matContentCopy" @click="copy(getAddress(false))" aria-label="copy location address"></q-btn>
                        </div>
                      </div>       
                      <div class="location-coords">
                        <div class="location-coords-details">
                          <q-item-label class="text-body2" v-if="latitude">Coordinates: <span class="text-weight-medium">{{ latitude }}, {{ longitude }}</span></q-item-label>
                        </div>
                        <div class="location-coords-copy">
                          <q-btn flat round color="primary" :icon="matContentCopy" @click="copy(latitude + ',' + longitude)" aria-label="copy location coordinates"></q-btn>
                        </div>
                      </div>
                      <q-separator class="myip-separator"></q-separator>
                      <div class="location-summary">
                        <q-item-label class="text-body2" v-if="extrapresent && extra.FreebaseID">Summary: <span class="text-weight-medium"><a class="myip-external-link" :href="summaryLink(extra)" target="_blank" rel="noopener, noreferrer, nofollow">[Google]</a></span></q-item-label>
                        <q-item-label class="text-body2" v-if="extrapresent && extra.OpenStreetMapID">OpenStreetMap: <span class="text-weight-medium"><a class="myip-external-link" :href="osmLink(extra)" target="_blank" rel="noopener, noreferrer, nofollow">[Map]</a></span></q-item-label>
                        <q-item-label class="text-body2">Weather: <span class="text-weight-medium"><a class="myip-external-link" :href="weatherLink(latitude, longitude)" target="_blank" rel="noopener, noreferrer, nofollow">[Forecast]</a></span></q-item-label>                        
                      </div>
                      <q-separator class="myip-separator"></q-separator>
                      <div class="location-summary">
                        <q-item-label class="text-body2" v-if="extrapresent && extra.OfficialSite!=''">Official site: <span class="text-weight-medium"><a class="myip-external-link" :href="offsiteLink(extra)" target="_blank" rel="noopener, noreferrer, nofollow">{{ extra.OfficialSite }}</a></span></q-item-label>
                        <q-item-label class="text-body2" v-if="extrapresent && extra.Population!=''">Population: <span class="text-weight-medium">{{ populationText(extra) }}</span></q-item-label>
                        <q-item-label class="text-body2" v-if="extrapresent && extra.Elevation!=''">Elevation: <span class="text-weight-medium">{{ extra.Elevation }}m</span></q-item-label>
                        <q-item-label class="text-body2" v-if="extrapresent && extra.Area!=''">Area: <span class="text-weight-medium">{{ extra.Area }}</span></q-item-label>
                        <q-item-label class="text-body2" v-if="timezone">Timezone: <span class="text-weight-medium">{{ timezone }}</span></q-item-label>

                      </div>
                    </div>
                </div>
                  <div v-if="images.length>0">
                    <div class="gallery">
                      <div class="image-container">
                        <q-img
                          :src="`${basepath}${images[thumbimageIndex].image}`"
                          :key="images[thumbimageIndex].image"
                          :alt="`Location image ${thumbimageIndex + 1}`"
                          no-transition
                          height="100%"
                          width="100%"
                          fit="contain"
                          loading="eager"
                          @click="goToSource(images, thumbimageIndex)"
                        >
                        </q-img>
                      </div>
                      <div class="text-container" @click="goToSource(images, thumbimageIndex)">
                        {{images[thumbimageIndex].desc}}
                      </div>
                      <div class="navigaton-container">              
                        <q-btn v-for="(image, index) in images" :key="image" :icon="imgIcon(index)" @click="thumbimageIndex=index" :aria-label="`Location image button ${index + 1}`" class="" round dense size="8px"></q-btn>
                      </div>
                    </div>
                  </div>                
              </div>
            </q-item-section>
          </q-item>

          <q-separator></q-separator>
          <q-item class="myip-item-hasbadge">
            <q-badge color="indigo-6" text-color="white" :label="travelbadgeLabel" class="myip-badge"></q-badge>
            <q-item-section>
              <div v-if = "travelAdviceLoading || locationLoading">
                <q-linear-progress style="margin-top:7px; margin-bottom:7px;" :value="progress" :instant-feedback="true" aria-label="Travel guide is loading.."/>
              </div>
              <div v-else>
                <div style="height:18px"></div>
              </div>

              <div :class="{locationroller: travvis}">

              <div :class="{locationblur: travelAdviceLoading || locationLoading}" >
                <div v-if="travelAdvice">
                  <q-item-label class="text-body1 text-weight-medium" v-html="travelAdvice"></q-item-label> 
              
                  <q-separator></q-separator>
                  <q-item class="myip-item-hasbadge">
                    <q-badge color="indigo-6" text-color="white" label="PERMALINK" class="myip-badge"></q-badge>              
                    <q-item-section class="perma-margin">
                      <div class="travel-link">
                          <div class="travel-link-details">
                            <q-item-label class="text-body1 text-weight-medium" ><span class="text-weight-medium"><a class="myip-external-link" :href="travelPermaLink" >Travel guide for: {{travelLocationFull}}</a></span></q-item-label>              
                          </div>
                          <div class="travel-link-copy">
                            <q-btn flat round color="primary" :icon="matContentCopy" @click="copy(travelPermaLink)" aria-label="copy permanent link"></q-btn>
                          </div>                
                      </div>
                    </q-item-section>
                  </q-item>
                  <q-separator></q-separator>
                </div>

                <div v-else>
                  <div v-if="!travelAdviceLoading && !locationLoading">
                    <q-item-label class="text-body2">not detected</q-item-label>
                  </div>
                  <div v-else>
                    <q-item-label class="text-body2"></q-item-label>
                  </div>
                </div>              
              </div>

              </div> 

            </q-item-section>
          </q-item>
                      
        </q-list>

    <div class="locationrollerbtn">
      <q-btn
        v-show="!travelAdviceLoading && !locationLoading"
        class="myip-btn-tvis"
        aria-label="show/hide AI travel advice"
        rounded
        outline
        no-wrap
        size="md"
        :icon-right="travvis ? matKeyboardDoubleArrowDown : matKeyboardDoubleArrowUp "
        :label="travvis ? 'Show More' : 'Show Less'"
        @click="updateVis(travkey, 1)"
      ></q-btn>
     </div>



      </div>

    
  </q-card>

</template>

<style>

#map {
  height: 40vh;
  width: 100%;
}

/* Import Leaflet CSS */
@import 'leaflet/dist/leaflet.css';

.q-img__content>div {
  padding:4px 4px 4px 4px !important;
}
.location-header{
  width:100%;
  margin-bottom: 16px;
}
.location-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.location{
  display: flex;  
  flex-direction: column;
  width:100%;
}

.locationblur{
  filter:blur(3px);
}

.locationroller{
  height:80px;
  overflow:clip;
}
.locationrollerbtn{
  text-align: center;
  padding:10px 0px 10px 0px;
  position:absolute;
  width:100%;
  bottom :0px;
  left: 50%;
  transform: translateX(-50%);  
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0), 
    rgba(0, 0, 0, 1)   
  );  
}
.locationcap2{  
  margin-top:8px  ; 
}

.perma-margin{
  margin-bottom:20px;
}

.location-address{
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}
.location-address-details{
  width:100%;
}
.location-flag{
  margin-left:16px;
  border: 1px solid #444;
  vertical-align: middle;  
}
.location-address-copy{
  width:42px;
}

.location-coords{
  display: flex;
  flex-direction: row;
}
.location-coords-details{
  display: flex;
  align-items: center;
  width:100%;
}
.location-coords-copy{
  width:42px;
}

.location-summary{
  width:100%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:150px;
  margin:0px 0px 0px 16px;
  border: 1px solid rgb(25, 118, 210); /*primary*/
  border-radius:2%;
}
.image-container {
  height: 150px; /* Set fixed height */
  width: 150px; /* Set fixed width if desired */
  overflow: hidden; 
  cursor: pointer;
  padding: 3px 3px;
}
.text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align:center;
  height:53px;
  width:150px;  
  font-size:0.75rem;
  line-height:1rem;
  padding:1px 3px;
  cursor: pointer;
}
.navigaton-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%; /* Full width of the container */
  max-width: 150px; /* Match the image width */
  padding: 8px 1px 8px 1px;
  background:rgb(25, 118, 210); /*primary*/
  
}

.gallery{
    color: #eee;
    background: #456;
}
body.body--dark .gallery{
    color: #eee;
    background: #345;
}

.travel-link{
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;
}
.travel-link-details{
  display: flex;
  align-items: center;
  width:100%;
}
.travel-link-copy{
  width:42px;
}

</style>

<script setup>

  import { copy, getVisState, toggleVis } from '../utils.js';
  import { matRadioButtonChecked, matRadioButtonUnchecked, matContentCopy, matKeyboardDoubleArrowUp,  matKeyboardDoubleArrowDown, matMyLocation } from '@quasar/extras/material-icons'
  import { ref, onMounted, onUnmounted, watch, nextTick, computed} from 'vue';
  import * as L from 'leaflet';
  import 'leaflet/dist/leaflet.css';

  const props = defineProps({
    havelocation: String,
    locality: String,
    subdiv: String,
    city: String,
    country: String,
    countryiso: String,
    continent: String,
    postcode: String,
    latitude: String,
    longitude: String,
    timezone: String,
    images: Array,
    extra: Object,
    extrapresent: Boolean,
    basepath: String,
    extendedLoading: Boolean,
    locationLoading:Boolean,
    locationrefresh:Boolean,
    locationtitle:String,
    locationcaption1:String,
    locationcaption2:String,
    locationbadge:String,

    travelAdvice: String,
    travelAdviceLoading: Boolean,
    travelLocation: String,
    travelLocationFull: String,
    travelPermaLink:String,
  });

  const travelbadgeLabel = computed(() =>{
    if (props.travelAdviceLoading || props.locationLoading){
      return "GENERATING AI TRAVEL ADVICE..";
    }else{
      return "TRAVEL ADVICE";
    }
  });


  

  // Fix icon paths (necessary if icons are not displaying)
  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
      iconRetinaUrl: new URL('leaflet/dist/images/marker-icon-2x.png', import.meta.url).href,
      iconUrl: new URL('leaflet/dist/images/marker-icon.png', import.meta.url).href,
      shadowUrl: new URL('leaflet/dist/images/marker-shadow.png', import.meta.url).href,
  });

  const mapContainer = ref(null);
  const map = ref(null);
  const marker = ref(null);
  const zoom = ref(0);
  const thumbimageIndex = ref(0);

  const tabdefault = true;              //default for this card
  const tabkey = 'locationVis';
  const tabvis = ref(tabdefault);   
  getVisState(tabvis, tabkey, tabdefault);

  const travdefault = false;              
  const travkey = 'locationTvis';
  const travvis = ref(travdefault);   
  getVisState(travvis, travkey, travdefault);

  const updateVis = (localKey, keyidx) => {
    //refs (eg tabvis) when used in a template get automatically unwrapped, need to call toggleVis from within script setup to maintain reactivity
    var newvisState = false;
    switch (keyidx)
    {
      case 0:
        toggleVis(tabvis, localKey);
        newvisState = tabvis.value;
        break;
      case 1:
        toggleVis(travvis, localKey);
        newvisState = travvis.value;
        break;
    }
    if (newvisState) {
      nextTick(() => {
        invalidateMapSize();
      });
    }
  }

  const progress = ref(0);
  const step = 1/30;
  let interval;

  const clearProgress = () => {
    progress.value = 0;
    if (interval) clearInterval(interval); // Clear the interval when the component is unmounted
  }
  const showProgress = () =>{
    interval = setInterval(() => {
      if (progress.value < 1) {
        //console.log(props.travelAdviceLoading);
        if (props.travelAdviceLoading){          
          progress.value += step; // Increment by 10% every second
        }else{
          clearProgress();            
        }
      }else{
        clearProgress();
      }
    }, 1000); // 1000 milliseconds = 1 second
  }
  watch(
    () => props.travelAdviceLoading, 
    (loading) => {
      clearProgress();
      if (loading){
        showProgress();
      }
    },{
      immediate:true,
    }
  );

  const initializeMap = () => {
      const initialLat = props.latitude ?? 0;
      const initialLng = props.longitude ?? 0;

      console.log('init Current Zoom Level:', zoom.value);

      map.value = L.map(mapContainer.value, {
          center: [initialLat, initialLng],
          zoom: zoom.value,
          minZoom: 2, // Set the minimum zoom level
          maxBounds: [[-90, -180],[90, 180],], // Limit panning to the world bounds
          maxBoundsViscosity: 1.0, // Prevent panning outside the bounds        
      });
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; OpenStreetMap contributors',
          noWrap: true, // Prevent tiles from wrapping horizontally
      }).addTo(map.value);

      delete L.Icon.Default.prototype._getIconUrl;
      L.Icon.Default.mergeOptions({
          iconRetinaUrl: new URL('leaflet/dist/images/marker-icon-2x.png', import.meta.url).href,
          iconUrl: new URL('leaflet/dist/images/marker-icon.png', import.meta.url).href,
          shadowUrl: new URL('leaflet/dist/images/marker-shadow.png', import.meta.url).href,
      });

      map.value.on('click', (e) => {
          if (!props.locationLoading && !props.travelAdviceLoading){ //&& !props.travelAdviceLoading comment to stop allow a user to keep clicking areas
            map.value.setView(e.latlng);
            if (marker.value) {
                map.value.removeLayer(marker.value);
            }
            marker.value = L.marker(e.latlng).addTo(map.value);
            const latitude = e.latlng.lat.toFixed(6);
            const longitude = e.latlng.lng.toFixed(6);
            //alert(`Latitude: ${lat}\nLongitude: ${lng}`);
            zoom.value = map.value.getZoom();
            console.log('click Current Zoom Level:', zoom.value);
            emit('mapLoc', { latitude, longitude });
          }
      });

      if (props.latitude != null && props.longitude != null) {
          const initialPosition = L.latLng(props.latitude, props.longitude);
          marker.value = L.marker(initialPosition).addTo(map.value);
      }
  }
  const invalidateMapSize = () => {
    if (map.value) {
      map.value.invalidateSize();
    }
  }
  
  const emit = defineEmits([
    'deviceLoc', 'mapLoc'
  ]);
  const summaryLink = (extra) => {
    return "https://www.google.com/search?kgmid=" + extra.FreebaseID;
  };
  const osmLink = (extra) => {
    return "https://www.openstreetmap.org/relation/" + extra.OpenStreetMapID;
  };
  const offsiteLink = (extra) => {
    return extra.OfficialSite;
  };
  const weatherLink = (latitude, longitude) => {
    return "https://www.visualcrossing.com/weather-forecast/" + latitude + ',' + longitude + '/metric';
  };
  const populationText = (extra) => {    
    var pop = extra.Population;
    if (extra.PopDateStamp){
      pop += ' (' + extra.PopDateStamp + ')';
    }
    return pop;
  };
  const getAddress = (mode) => {
    var retval='';
    var n = '';
    var img = '';
    if (mode){
      n = '<br />';
      if (props.countryiso){
        img = "<img class='location-flag' src='" + props.basepath + "flags/" + props.countryiso.toLocaleLowerCase() + "32.png' alt='" + props.country + " flag' label='" + props.country + " flag' width='24' height='16'>";
      }
    }else{  
      n = ', ';
    }
    retval += props.locality + n ;
    if (props.city!=props.locality){
      if (props.city){
        retval += props.city + n;
      }
    }
    if (props.subdiv){
      retval += props.subdiv + ' ';
    }
    if (props.postcode) {
      retval += props.postcode;
    }    
    retval += n + props.country + img;

    return retval;
  };
  const imgIcon = (index) => {
    return (thumbimageIndex.value==index) ? matRadioButtonChecked : matRadioButtonUnchecked;
  };
  const goToSource = (images) => {
    window.open(images[thumbimageIndex.value].attrib, '_blank', 'noopener, noreferrer');
  };
  const getLoc = () => {
    if (!props.travelAdviceLoading && !props.locationLoading){
      emit('deviceLoc');
    }
  };

  
  watch(
      () => [props.latitude, props.longitude],
      ([newLat, newLng]) => {
          if (newLat != null && newLng != null) {
              if (map.value == null) {
                  console.log('sadsadasdasd');
                  initializeMap();
              } else {
                  const newPosition = L.latLng(newLat, newLng);
                  map.value.setView(newPosition, zoom.value);
                  if (marker.value) {
                      map.value.removeLayer(marker.value);
                  }
                  marker.value = L.marker(newPosition).addTo(map.value);
              }
          }
      },
      {
          immediate: false,
      }
  );


  onMounted(() => {
    showProgress();

    if (props.latitude != null && props.longitude != null) {
        zoom.value = 13;
        initializeMap();
    }        
  });

  onUnmounted(() => {
    clearProgress();
  });

</script>
