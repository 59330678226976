import { createApp } from 'vue'
//import { createHead } from '@unhead/vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router.js'; // Import the router

const app = createApp(App);
app.use(router); 

//const head = createHead();
//app.use(head);

app.use(Quasar, quasarUserOptions);

app.mount('#app');

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js').then(function() {
    console.log('Service Worker Registered');
  });
}

/*
let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  deferredPrompt = e;
  //showInstallPrompt();

    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      deferredPrompt = null;
    });

});
*/

/*
function showInstallPrompt() {
  // Display a custom install button or dialog
  const installButton = document.querySelector('#custom-install-button');
  installButton.style.display = 'block';

  installButton.addEventListener('click', (e) => {
    console.log("e",e);
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      deferredPrompt = null;
    });
  });
}
*/