<template>
  <q-card class="myip-info-card" >
    <q-item class="myip-item-cardtitle">
      <q-item-section avatar>
          <q-avatar square ><img src="../assets/guides.svg" width="40" height="40" alt="Latest Travel Guides"></q-avatar>
      </q-item-section>
      <q-item-section>
        <q-item-label class="myip-info-title text-h5">Recent Travel Guides</q-item-label>
        <q-item-label caption>
          Your latest FOMO
        </q-item-label>
      </q-item-section>
      <q-btn
        class="myip-btn-vis"
        aria-label="show/hide Latest Guides panel"
        round
        flat
        size="md"
        :icon="localvis ? matKeyboardDoubleArrowUp : matKeyboardDoubleArrowDown"
        @click="updateVis(localkey)"
      ></q-btn>
    </q-item>

    <q-slide-transition >
      <div v-show="localvis">
        <q-list>
          <q-separator></q-separator>

          <div v-if="latest.length>0">
            <div class="latest-wrap">
              <div class="latest-panel" v-for="(image) in latest" :key="image.id">
                   
                <a :href="image.link" :alt="image.location">
                <div class="content-wrap">
                  <q-img  
                    :src="`${basepathflags}${image.flag}`"
                    :aria-label="`Travel Guide for ${image.name},${image.location}`" 
                    :alt="`Travel Guide for ${image.name},${image.location}`" 
                    loading-show-delay=0
                    fit="none"
                    loading="eager"
                    no-spinner=true
                    no-transition=true
                    style="width: 128px; height: 90px; "
                    >
                  </q-img>                  
                </div>
                </a>
                <a :href="image.link">
                <div class="latest-name">
                  {{ image.name }}
                </div>            
                </a>    
                <div class="latest-date">
                  {{ image.date }}
                </div>                
                

              </div>
            </div>
          </div>                


        </q-list>
      </div>
    </q-slide-transition>
  </q-card>
</template>
<style scoped>

.latest-wrap {
  display: flex;
  margin-top:10px;
  flex-wrap: wrap;
  justify-content: center; /* Centers the panels */
  /*margin: -10px; /* Negative margin to offset child margins */
}

.latest-panel {
  display:flex;
  width: 140px;
  margin: 10px; /* Adjusts space around each panel */
  border: 1px solid #333;
  border-radius: 5px;
  text-align: center; /* Centers content within the panel */
  background: #2c515a;
  display: flex;
  flex-direction: column;  
}

body.body--dark .latest-panel{
  background: #111;
}

.latest-panel:hover{
  cursor: pointer;
  border: 1px solid #fff;
}

body.body--dark .latest-panel:hover{
  cursor: pointer;
  border: 1px solid #bbb;
}

.content-wrap{
  padding-top:5px;
  padding-left:5px;
  padding-right:6px;
  margin-bottom:5px;
}
.latest-name {
  margin-top: 0px; /* Space between image and name */
  margin-bottom:8px;
  font-size: 14px; /* Adjust font size as needed */
  padding-left:2px;
  padding-right:2px;
  
}

.latest-date {
  margin-top: auto; /* Space between image and name */
  font-size: 10px; /* Adjust font size as needed */
  color: #d7d7d7; /* Inherits text color */
  background-color: #333;  
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #4c717a;
}
body.body--dark .latest-date{
  background: #333;
}


.latest-panel a {
  text-decoration: none; /* Removes underline from link */
  color: #d7d7d7; /* Inherits text color */
}



</style>
<script setup>
  import { getVisState, toggleVis } from '../utils.js';
  import { matKeyboardDoubleArrowUp, matKeyboardDoubleArrowDown } from '@quasar/extras/material-icons';
  import { ref } from 'vue';

  const defaultvis = true;              //default for this card
  const localkey = 'browserVis';
  const localvis = ref(defaultvis); 

  getVisState(localvis, localkey, defaultvis);

  //refs (eg localvis) when used in a template get automatically unwrapped, need to call toggleVis from within script setup to maintain reactivity
  const updateVis = (localKey) => {
    toggleVis(localvis, localKey);
  }

  //const props = 
  defineProps({
    latest: Array,
    basepathflags: String,
    extendedLoading: Boolean,

  });


</script>
