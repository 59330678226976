import script from "./IPExtended.vue?vue&type=script&setup=true&lang=js"
export * from "./IPExtended.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QList from 'quasar/src/components/item/QList.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QItem,QItemSection,QAvatar,QItemLabel,QBtn,QSlideTransition,QList,QSeparator,QBadge,QMenu});
